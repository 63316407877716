<template>
  <div>
    <div class="headers">
      <div class="head_wrap">
        <div class="head" @click="openUrl">已有账号？去登录</div>
      </div>
    </div>

    <div class="tabs_bg">
      <div class="title">供应商注册</div>
    </div>

    <div class="formbox">
      <div class="form">
        <el-form ref="ruleForm" :model="form" status-icon :rules="rules" label-width="150px" label-position="left">
          <el-form-item label="营业执照" prop="busiLicense">
            <el-upload class="upload-demo" accept=".png,.jpeg,.jpg" :file-list="fileList" :limit="1" name="multipartFile" :action="action" :on-success="busiLicenseSuccess" :before-upload="beforeUploads" :on-remove="handleRemove" list-type="picture">
              <el-button type="primary" plain>点击上传</el-button>
              <div slot="tip" class="el_upload__tip">
                <i class="el-icon-warning"></i>
                <span>请确保营业执照信息清晰可见，图片格式仅支持jpg、jpeg、png，图片大小不超过5MB。</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="供应商名称" prop="supplierName">
            <el-input v-model.trim="form.supplierName" placeholder="上传营业执照自动识别" disabled></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="creditCode">
            <el-input v-model="form.creditCode" placeholder="上传营业执照自动识别" disabled></el-input>
          </el-form-item>
          <el-form-item label="LOGO" prop="logoPic" class="logo_pic">
            <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action" :show-file-list="false" :on-success="handleSuccess" :before-upload="beforeUploads" :on-remove="handleRemoveLogo" :disabled="form.imgurl ? true : false">
              <img v-if="form.imgurl" :src="form.imgurl" class="logoimg" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <i v-if="form.imgurl" class="delpic el-icon-error" @click="handleRemoveLogo"></i>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="form.contacts" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="telphone">
            <el-input v-model="form.telphone" placeholder="请输入手机号码（该手机号码为登录账号）"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input v-model="form.password" placeholder="请输入登录密码，密码至少8位数且必须包含大写字母、小写字母、数字"></el-input>
            <el-tooltip content="密码至少8位数且必须包含大写字母、小写字母、数字" placement="top" effect="light">
              <i class="el-icon-warning-outline tips"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" placeholder="请再次输入登录密码"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="local">
            <el-cascader class="cascader" ref="cascader" v-model="form.local" :options="options" placeholder="请选择地区" :props="{ value: 'code', label: 'name' }" @change="handleChange" style="display: block"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="供应商简介" prop="supplierIntroduce">
            <el-input type="textarea" :rows="4" placeholder="请输入供应商简介" v-model="form.supplierIntroduce"></el-input>
          </el-form-item>
          <div class="sumbit">
            <el-button type="primary" plain @click="openUrl">登录</el-button>
            <el-button type="primary" @click="sumbit('ruleForm')">注册</el-button>
          </div>
        </el-form>
      </div>
    </div>

    <footers />
  </div>
</template>
<script>
function createdForm() {
  return {
    supplierName: "",
    logoPic: "",
    imgurl: "",
    supplierIntroduce: "",
    contacts: "",
    address: "",
    telphone: "",
    password: "",
    busiLicense: "",
    busiLicenseUrl: "",
    creditCode: "",
    confirmPassword: "",
    provinceCode: "",
    province: "",
    cityCode: "",
    city: "",
    areaCode: "",
    area: "",
    local: [],
  }
}
export default {
  data() {
    return {
      form: createdForm(),
      options: [],
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      rules: {
        supplierName: [{ required: true, message: "缺少供应商名称", trigger: "blur" }],
        supplierIntroduce: [{ required: true, message: "请输入供应商自我介绍", trigger: "blur" }],
        contacts: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        local: [{ required: true, message: "请选择地区", trigger: "change" }],
        telphone: [
          { required: true, message: "请输入电话号码（手机号码是登录账号）", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              // 只有在值不为空时才发起请求验证手机号是否已存在
              if (value) {
                // 校验手机号是否已被注册
                this.$axios
                  .get(`${this.$api.selectSupplierPhone}/${value}`)
                  .then(res => {
                    // 如果响应-1 表示该手机号已存在
                    if (res.data.code == -1) {
                      callback(new Error(res.data.desc))
                    } else {
                      callback() // 手机号可以使用，通过校验
                    }
                  })
                  .catch(error => {
                    console.error("校验手机号是否存在时发生错误:", error)
                    callback(new Error("服务器错误，请稍后再试"))
                  })
              } else {
                callback(new Error("手机号码不能为空"))
                // callback() // 手机号格式不正确，不过这里仍通过校验，因为前面应该已经有关于手机号格式的规则校验
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 8,
            message: "密码格式不正确",
            validator: (rule, value, callback) => {
              if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)) {
                callback(new Error("密码必须包含大写字母、小写字母、数字"))
              } else {
                callback()
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入登录密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("两次输入的密码不一致!"))
              } else {
                callback()
              }
            },
            trigger: "blur",
          },
        ],
        busiLicense: [{ required: true, message: "请上传营业执照", trigger: "change" }],
        creditCode: [{ required: true, message: "缺少统一社会信用代码", trigger: "blur" }],
      },
      fileList: [],
    }
  },
  mounted() {
    this.getAreas()
  },
  methods: {
    handleChange() {
      this.form.provinceCode = this.$refs["cascader"].getCheckedNodes()[0].path[0]
      this.form.cityCode = this.$refs["cascader"].getCheckedNodes()[0].path[1]
      this.form.areaCode = this.$refs["cascader"].getCheckedNodes()[0].path[2]
      this.form.province = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[0]
      this.form.city = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[1]
      this.form.area = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]
    },
    supplierregister() {
      this.$axios.post(this.$api.register, this.form).then(res => {
        if (res.data.code == 100) {
          this.$message({ showClose: true, message: "注册成功", type: "success" })
          // this.form = Object.assign({}, createdForm())
          // this.fileList = []
          // 注册成功跳去登录
          setTimeout(() => {
            this.$router.replace("/login")
          }, 1000)
        } else {
          this.$message({ showClose: true, message: res.data.desc, type: "error" })
        }
      })
    },
    sumbit(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.$axios.get(`${this.$api.selectSupplierName}/${this.form.supplierName}`).then(res => {
            if (res.data.code == 100) {
              this.supplierregister()
              // this.$refs.ruleForm.resetFields()
            } else {
              this.$message({ showClose: true, message: res.data.desc, type: "error" })
            }
          })
        } else {
          return false
        }
      })
    },
    openUrl() {
      this.$router.push("/login")
    },
    // 删除logo
    handleRemoveLogo() {
      this.form.imgurl = ""
    },
    // logo上传成功
    handleSuccess(res) {
      if (res.code == 100) {
        this.form.logoPic = res.result.fileId
        this.form.imgurl = res.result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 删除营业执照
    handleRemove() {
      this.form.busiLicense = ""
      this.form.creditCode = ""
      this.form.supplierName = ""
      this.fileList = []
    },
    // 营业执照上传成功
    busiLicenseSuccess(res) {
      if (res.code == 100) {
        this.form.busiLicense = res.result.fileId
        this.form.busiLicenseUrl = res.result.fileUrl
        this.busiLicenseOCR(this.form)
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // OCR识别营业执照自动填充
    busiLicenseOCR(e) {
      if (e.busiLicenseUrl) {
        this.$axios
          .get(this.$api.getBizLicenseInfoOCR, {
            params: {
              imgUrl: e.busiLicenseUrl,
            },
          })
          .then(res => {
            if (res.data.code == 100) {
              this.form.supplierName = res.data.result.name
              this.form.creditCode = res.data.result.regNum
            } else {
              this.handleRemove()
            }
          })
      }
    },
    // 省市区
    getAreas() {
      this.$axios.get(this.$api.getDistrictInfo).then(res => {
        if (res.data.code == 100) {
          this.options = res.data.result
        }
      })
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
  },
}
</script>
<style lang="scss" scoped>
.headers {
  display: flex;
  justify-content: right;
  width: 100%;
  height: 44px !important;
  background-color: #ebfffb;

  .head_wrap {
    width: 280px;
    height: 44px;
    padding-right: 25px;
    background: url(../assets/img/user.png) no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .head {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tabs_bg {
  position: relative;
  width: 100%;
  height: 130px;
  background: url(../assets/img/head.png) no-repeat;
  background-size: cover;
  background-position: 80%;
  box-sizing: border-box;

  .title {
    width: 100%;
    height: 130px;
    line-height: 130px;
    color: #fff;
    font-size: 28px;
    font-family: "zzgf";
    text-align: center;
  }
}

.formbox {
  display: flex;
  justify-content: center;
  width: 900px;
  margin: 20px auto;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 60px;
  background-color: #fff;

  .form {
    min-width: 600px;

    .formgong {
      font-size: 16px;
      color: rgba(16, 16, 16, 0.9);
      padding-left: 10px;
      border-left: 3px solid #069bbc;
    }

    .usable_tel,
    .tips {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      color: #c0c4cc;
      cursor: pointer;
    }

    .usable_tel {
      .el-icon-error {
        color: #f56c6c;
      }

      .el-icon-success {
        color: #67c23a;
      }
    }

    .tips {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .logo_pic {
    position: relative;

    /deep/.el-form-item__content {
      width: 100px;
      height: 100px;
    }

    .logo_uploader {
      /deep/ .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      /deep/ .el-upload:hover {
        border-color: #069bbc;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #069bbc;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }

      .logoimg {
        width: 100px;
        height: 100px;
        display: block;
        object-fit: cover;
      }
    }

    .delpic {
      position: absolute;
      top: -12px;
      right: -15px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }
  }

  /deep/.el-input__inner {
    width: 100%;
    border: 1px solid #dcdfe6;
    border-radius: 10px;
    background-color: #fff;

    &:hover {
      border-color: #c0c4cc;
    }

    &:focus {
      border-color: #069bbc;
    }
  }

  /deep/.el-button:active {
    color: #fff;
    border-color: #2e9c63;
    background-color: #2e9c63;
  }

  .el_upload__tip {
    display: flex;
    align-items: center;
    color: #1a1a1a;

    .el-icon-warning {
      color: #069bbc;
      font-size: 16px;
    }

    span {
      margin-left: 6px;
    }
  }

  .sumbit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .el-button {
      width: 150px;
    }
  }
  .cascader {
    width: 100%;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 100%;
    }
  }
}
</style>
